import { Lifecycle, Device } from '@firebolt-js/sdk'
import {
  DevicePlatform,
  NetworkStatus,
  NetworkStatusCallback,
  NetworkStatusCheck,
  NetworkStatusSubscriber,
} from '../platforms'
import { AdeCloseReason } from '@adiffengine/engine-types'
export function convertAdeCloseToFireboltClose(
  reason: AdeCloseReason,
): Lifecycle.CloseReason {
  return reason === AdeCloseReason.TIMED_OUT
    ? Lifecycle.CloseReason.DONE
    : (reason as unknown as Lifecycle.CloseReason)
}
export function exitPlatform(
  reason: AdeCloseReason = AdeCloseReason.USER_EXIT,
) {
  Lifecycle.close(convertAdeCloseToFireboltClose(reason))
}
type NetworkType = 'wifi' | 'ethernet' | 'hybrid'
type NetworkState = 'connected' | 'disconnected'
interface NetworkInfo {
  state: NetworkState
  type: NetworkType
}
declare module '@firebolt-js/sdk' {
  namespace Device {
    function network(
      subscriber: (networkInfo: NetworkInfo) => void,
    ): Promise<number>
    function network(): Promise<NetworkInfo>
  }
}
const subscribeToNetworkStatus: NetworkStatusSubscriber = async (
  subscriber: NetworkStatusCallback,
) => {
  const listenerId = await Device.network(({ state }) => {
    subscriber(
      state === 'disconnected'
        ? NetworkStatus.Disconnected
        : NetworkStatus.Connected,
    )
  })
  return () => {
    Device.clear(listenerId)
  }
}
const networkStatus: NetworkStatusCheck = async () => {
  const { state } = await Device.network()
  return state === 'disconnected'
    ? NetworkStatus.Disconnected
    : NetworkStatus.Connected
}
export const platform: DevicePlatform = {
  exit: exitPlatform,
  subscribeToNetworkStatus,
  networkStatus,
}
