import {
  DevicePlatform,
  NetworkStatus,
  NetworkStatusCallback,
  NetworkStatusCheck,
  NetworkStatusSubscriber,
} from '../platforms'
import { AdeCloseReason } from '@adiffengine/engine-types'
import { Router } from '@lightningjs/sdk'
import Emittery from 'emittery'
import { Debugger } from '../../debugger'
const debug = new Debugger('SmartTV-Platform')
export function exitPlatform(
  _reason: AdeCloseReason = AdeCloseReason.USER_EXIT
) {
  console.warn('Closing')
  Router.navigate('exit')
}

interface NetworkListenerEvent {
  status: NetworkStatus
}

class NetworkListener extends Emittery<NetworkListenerEvent> {
  _status: NetworkStatus
  _listeners: Record<number, NetworkStatusCallback> = {}
  _ids = 0
  constructor() {
    super()
    this._status =
      navigator.onLine === true
        ? NetworkStatus.Connected
        : NetworkStatus.Disconnected
    window.addEventListener('online', this.wentOnline.bind(this))
    window.addEventListener('offline', this.wentOffline.bind(this))
  }
  set status(status: NetworkStatus) {
    debug.info('Setting Network Status to %s', status)
    if (status !== this._status) {
      this._status = status
      this.emit('status', status)
      this.notifyListeners()
    }
  }
  get status() {
    return this._status
  }

  wentOnline() {
    debug.info('Went Online')
    this.status = NetworkStatus.Connected
  }
  wentOffline() {
    debug.info('Went Offline')
    this.status = NetworkStatus.Disconnected
  }
  notifyListeners() {
    Object.values(this._listeners).forEach(l => {
      l(this.status)
    })
  }
  addListener(listner: NetworkStatusCallback) {
    const id = this._ids++
    this._listeners[id] = listner
    return () => {
      this.removeListner(id)
    }
  }
  removeListner(id: number) {
    if (this._listeners[id]) delete this._listeners[id]
  }
}
let statusListener = new NetworkListener()
const subscribeToNetworkStatus: NetworkStatusSubscriber = async (
  subscriber: NetworkStatusCallback
) => {
  return statusListener.addListener(subscriber)
}
const networkStatus: NetworkStatusCheck = async () => {
  return statusListener.status
}
export const platform: DevicePlatform = {
  exit: exitPlatform,
  subscribeToNetworkStatus,
  networkStatus,
}
