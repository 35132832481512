import { DeviceSettings as LGSettings } from './settings.lg'
import { DeviceSettings as SamungSettings } from './settings.samsung'
import { DeviceSettings as X1Settings } from './settings.x1'
import { DeviceSettings as SmartTVSettings } from './settings.smart-tv'
import {
  DeployEnvironment,
  DeviceType,
  DeviceTypes,
  PartialSettingsFile,
  isDeployEnvironment,
  isValidDeviceType,
} from '@adiffengine/engine-types'
import { isGoodString, safeWindowVar } from '../lib'

const Lookup: Record<DeviceType, PartialSettingsFile> = {
  [DeviceTypes.LG]: LGSettings,
  [DeviceTypes.SmartTV]: SmartTVSettings,
  [DeviceTypes.Android]: SmartTVSettings,
  [DeviceTypes.X1]: X1Settings,
  [DeviceTypes.Samsung]: SamungSettings,
}

export interface DeviceSettingsConfig {
  deviceConfig: PartialSettingsFile
  environment: DeployEnvironment
  inspector: boolean
}
export async function getDeviceConfig(
  platform: DeviceType
): Promise<PartialSettingsFile> {
  if (isValidDeviceType(platform)) {
    return Lookup[platform]
  } else {
    if (isGoodString(platform)) {
      console.warn(`Got invalid device type "${platform}", returning Smart TV`)
    }
    return Lookup[DeviceTypes.SmartTV]
  }
}
export function parseMode(mode: string = 'production'): DeviceSettingsConfig {
  const [environment, device = null, inspector = false] = mode.split(':')
  let windowType = safeWindowVar('__ADE_SETTINGS_DEVICE')
  console.info('Window Type / Env Type', windowType)

  const type = isValidDeviceType(device)
    ? device
    : isValidDeviceType(windowType)
      ? windowType
      : DeviceTypes.SmartTV
  return {
    deviceConfig: Lookup[type],
    environment: isDeployEnvironment(environment) ? environment : 'production',
    inspector: inspector.toString().toLowerCase() === 'true',
  }
}
