import { Device, Lifecycle, Metrics, Parameters } from '@firebolt-js/sdk'
import { Router, Settings, VideoPlayer } from '@lightningjs/sdk'
import { getAuth, signInAnonymously } from 'firebase/auth'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase

import {
  AdeCloseReason,
  AdeDeviceInfo,
  ContentItem,
} from '@adiffengine/engine-types'

import {
  AdeLifecycleBootOptions,
  LifecycleBootResponse,
  SearchIntentBootResponse,
} from './lifecycle-types'
import { ThorMetrics } from './metrics'

import { Debugger } from '../debugger'
import { defaultBackHandler } from '../lightning-tools'
import { ThorError } from '../thor-error'
import { paramsRoute } from './firebolt-utils'
import { getDevicePlatform } from '../platforms'
import { DevicePlatform } from '../platforms/platforms'
import { initializeParameters } from './params'

const debug = new Debugger('ade-lifecycle-helper')

export function isSeachIntentBootResponse(
  x: LifecycleBootResponse
): x is SearchIntentBootResponse {
  return x.path === 'search'
}

export class AdeLifecycleHelper extends ThorMetrics {
  private _state: Lifecycle.Event = 'inactive'
  private _currentPlayerItem: ContentItem | null = null
  private _app: Router.App

  constructor(app: Router.App) {
    super()
    this._handleInactive = this._handleInactive.bind(this)
    this._handlePlayerContent = this._handlePlayerContent.bind(this)
    Lifecycle.listen((event, value: { state?: string; previous?: string }) => {
      debug.info('Lifecycle.listen:', event, value)
      if (value.state === 'inactive' && value.previous !== 'initializing') {
        this.state = value.state
      } else if (value.state === 'unloading') {
        this._app.stage.application.closeApp()
        console.warn('app closed')
      }
    })
    this._app = app
    ThorError.addErrorHandler(this.error.bind(this))
    this._app.stage.application.on(
      'playerContentItemChanged',
      this._handlePlayerContent
    )
    this._app.stage.application.on('screenView', this.screenView.bind(this))
    this._app.stage.application.on(
      'videoTrackingEvent',
      this.trackVideoPlayerEvent.bind(this)
    )
    this._app.stage.application.on(
      'videoViewEvent',
      this.videoViewEvent.bind(this)
    )
    this._app.stage.application.on('searching', term => {
      this.search({
        type: 'searching',
        payload: {
          term,
        },
      })
    })
    this._app.stage.application.on('searched', result_count => {
      this.search({
        type: 'results',
        payload: {
          result_count: `${result_count}`,
        },
      })
    })
  }

  private _handlePlayerContent(c: ContentItem | null) {
    debug.info('Handle Player Content Changed', c)
    if (c === null && this._currentPlayerItem !== null) {
      debug.info('Sending Stop Content Metric')
      Metrics.stopContent()
    } else if (
      c !== null &&
      this._currentPlayerItem !== null &&
      c.id !== this._currentPlayerItem.id
    ) {
      debug.info('Sending Stop Content Metric on Content Switch')
      Metrics.stopContent() // <-- do this first.
      Metrics.startContent()
    } else if (c !== null && this._currentPlayerItem === null) {
      Metrics.startContent()
    }
    this._currentPlayerItem = c
  }

  set state(state: Lifecycle.Event) {
    if (this._state !== state) {
      // Ensure when this is emitted that this is in the right state,
      // to do so cache the values from from and to

      const from = this._state
      const to = state
      this.breadcrumb({
        type: 'app-state',
        data: {
          from,
          to,
        },
      })
      this._state = state
      this.emit('state', { from, to })
      if (state === 'inactive') {
        this._handleInactive()
      }
    }
  }
  private _handleInactive() {
    const hash = Router.getActiveHash()
    if (hash) {
      const [first] = hash.split('/')
      if (first && first.toLowerCase() === 'player') {
        debug.info('Inactive event, and on Player')
        VideoPlayer.pause()
        VideoPlayer.clear()
        if (this._currentPlayerItem) {
          const result = defaultBackHandler(
            this._app.application,
            this._currentPlayerItem.paths.details
          )
          if (!result) {
            Router.navigate('/home')
          }
        }
      }
    }
  }

  private _uid: string | null = null
  async getUserId(): Promise<string> {
    return this._uid === null ? this.authUser() : this._uid
  }
  async authUser() {
    try {
      const auth = getAuth()
      const anonUser = await signInAnonymously(auth)
      this._uid = anonUser.user.uid
      await Settings.set('userId', this._uid)
      return this._uid
    } catch (error) {
      console.warn(
        'Error with anonymous auth %s trying a saved version',
        error.message
      )
      let uid = Settings.get('userId')
      if (!uid) {
        uid = simpleUUID()
        Settings.set(uid)
      }
      return uid
    }
  }

  async boot(): Promise<LifecycleBootResponse> {
    debug.info('Lifecycle Boot - starting')
    await this.paramsInit()
    const boot = await this.paramsDeeplink()
    debug.info('Lifecycle Boot - done')
    console.info('[ Firebolt Calls ] Lifecycle Ready - calling')
    Lifecycle.ready()
    console.info('[ Firebolt Calls ] Lifecycle Ready - called')
    return boot
  }

  private _bootOptions: AdeLifecycleBootOptions = {
    sampleParams: {},
  }

  private _params: Parameters.AppInitialization | null = null
  // Parameters Init
  async paramsInit(): Promise<Parameters.AppInitialization> {
    const deviceInfo = await this.fetchDeviceInfo()
    if (this._params !== null) return this._params
    try {
      const env = Settings.get('app', 'environment', 'production')
      if (deviceInfo.isMock === true || env !== 'production') {
        console.info(
          '[ Firebolt Calls]  Not on production (%s) or on mock device (isMock === %s),  NOT Calling Parameters Initialization',
          env,
          deviceInfo.isMock
        )
        return this._bootOptions.sampleParams
      }

      console.info(
        '[ Firebolt Calls]  On production and NOT mock device,  Calling Parameters Initialization'
      )
      const uid = await this.getUserId()
      this._params = await initializeParameters(this._bootOptions, uid)
    } catch (e) {
      this.warn(`Error with Parameters Initialization: ${e.message}`, e)
      this._params = {}
    }
    return this._params
  }
  async paramsDeeplink() {
    const bootParams = await this.paramsInit()
    return paramsRoute(bootParams)
  }

  // Platform Info
  private _deviceInfo: AdeDeviceInfo | null = null
  set deviceInfo(info: AdeDeviceInfo | null) {
    debug.info('Setting device info to', info)
    this._deviceInfo = info
    if (info !== null) {
      this.emit('deviceInfo', info)
    }
  }

  get deviceInfo() {
    return this._deviceInfo
  }
  private _mockDevice() {
    this._deviceInfo = {
      distributor: 'ADE',
      model: 'xi6',
      make: 'Arris',
      platform: 'WPE',
      isMock: true,
    }
    return this._deviceInfo
  }
  async fetchDeviceInfo(): Promise<AdeDeviceInfo> {
    const onProduction = Settings.get('app', 'environment') === 'production'
    try {
      if (this.deviceInfo !== null) return this.deviceInfo
      if (onProduction) {
        console.info('On Production calling for distributor info')
        return Promise.all([
          Device.distributor(),
          Device.model(),
          Device.make(),
          Device.platform(),
        ]).then(([distributor, model, make, platform]) => {
          this.deviceInfo = {
            distributor,
            model,
            make,
            platform,
          }
          debug.info('Device Info', this.deviceInfo)
          return this.deviceInfo
        })
      } else {
        console.info('Not on production so using mock device.')
        return this._mockDevice()
      }
    } catch (error) {
      this.warn(
        `Error getting platform info, creating mock device ${error.message}`,
        error
      )
      this._deviceInfo = {
        distributor: 'mock',
        model: 'mock',
        make: 'mock',
        platform: 'lightning',
      }
      return this._deviceInfo
    }
  }
  private _currentDevice: undefined | DevicePlatform = undefined
  get currentPlatform(): DevicePlatform {
    if (this._currentDevice === undefined) {
      this._currentDevice = getDevicePlatform()
    }
    return this._currentDevice
  }
  exit(reason: AdeCloseReason = AdeCloseReason.USER_EXIT) {
    this.breadcrumb({
      type: 'exit',
      level: 'info',
      timestamp: new Date().getTime(),
    })

    this.currentPlatform.exit(reason)
    console.warn('Exited')
  }
}

function simpleUUID() {
  let d = new Date().getTime() //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return `mock:${(c === 'x' ? r : (r & 0x3) | 0x8).toString(16)}`
  })
}
