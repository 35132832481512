import { DevicePlatform } from '../platforms'

// declare namespace tizen {
//   namespace application {
//     function getCurrentApplication(): {
//       exit(): void
//     }
//   }
// }

export const platform: DevicePlatform = {
  exit() {
    try {
      tizen.application.getCurrentApplication().exit()
    } catch (error) {
      console.error('Error exiting Tizen App')
    }
  },
}
